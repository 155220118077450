import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import NFTContractABI from "./NFTContractABI.json";
import initializeWeb3 from "./web3Service";
import "../App.css";

Modal.setAppElement("#root"); // Set the app element to the root element
const NFTDisplay = () => {
  const [web3, setWeb3] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [account, setAccount] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNft, setSelectedNft] = useState(null);
  const [blurred, setBlurred] = useState(false);

  useEffect(() => {
    const loadWeb3AndData = async () => {
      const web3Instance = await initializeWeb3();
      if (web3Instance) {
        setWeb3(web3Instance);
        await loadBlockchainData(web3Instance);
      } else {
        console.error("Failed to initialize web3");
      }
    };
    loadWeb3AndData();

    const handleScroll = () => {
      const nftContainer = document.querySelector('.nft-container');
      if (nftContainer.scrollTop > 0) {
        setBlurred(true);
      } else {
        setBlurred(false);
      }
    };

    const nftContainer = document.querySelector('.nft-container');
    nftContainer.addEventListener('scroll', handleScroll);

    return () => {
      nftContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadBlockchainData = async (web3) => {
    try {
      const accounts = await web3.eth.getAccounts();
      if (accounts.length > 0) {
        setAccount(accounts[0]);
        const networkId = await web3.eth.net.getId();
        const networkData = NFTContractABI.networks[networkId];
        if (networkData) {
          const contract = new web3.eth.Contract(
            NFTContractABI.abi,
            networkData.address
          );
          const balance = await contract.methods.balanceOf(accounts[0]).call();
          let nfts = [];
          for (let i = 0; i < balance; i++) {
            const tokenId = await contract.methods
              .tokenOfOwnerByIndex(accounts[0], i)
              .call();
            const tokenURI = await contract.methods.tokenURI(tokenId).call();
            const response = await fetch(tokenURI);
            const metadata = await response.json();
            nfts.push(metadata);
          }
          setNfts(nfts);
        } else {
          window.alert("Smart contract not deployed to detected network.");
        }
      }
    } catch (error) {
      console.error("Error loading blockchain data", error);
    }
  };

  const connectWallet = async () => {
    try {
      const web3Instance = await initializeWeb3();
      if (web3Instance) {
        // Request accounts each time to prompt MetaMask to switch accounts
        await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: {} }],
        });
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setWeb3(web3Instance);
          await loadBlockchainData(web3Instance);
        }
      }
    } catch (error) {
      console.error("Failed to connect wallet", error);
    }
  };

  const openModal = (nft) => {
    setSelectedNft(nft);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedNft(null);
  };

  const disconnectWallet = () => {
    setWeb3(null);
    setAccount(null);
    setNfts([]);
    setModalIsOpen(false);
    setSelectedNft(null);
  };

  return (
    <div className="app-container">
      <div className="header">
        <div className="top-right">
          {account ? (
            <button className="disconnect-button" onClick={disconnectWallet}>
              Disconnect
            </button>
          ) : (
            <button className="connect-button" onClick={connectWallet}>
              Connect Wallet
            </button>
          )}
        </div>
        <h1 className="title">Sewer Swag Bag</h1>
        <p>{account}</p>
      </div>

      <div className="nft-container">
        {account && (
          <div className="nft-gallery">
            {nfts.map((nft, index) => (
              <div
                className="nft-card"
                key={index}
                onClick={() => openModal(nft)}
              >
                <img src={nft.image} alt={nft.name} />
                <h3>{nft.name}</h3>
                <p>{nft.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="nft-modal"
        overlayClassName="nft-modal-overlay"
      >
        {selectedNft && (
          <>
            <h2>{selectedNft.name}</h2>
            <img src={selectedNft.image} alt={selectedNft.name} />
            <p>{selectedNft.description}</p>
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default NFTDisplay;
