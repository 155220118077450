import React, { useEffect } from 'react';
import * as THREE from 'three';

const BackgroundEffect = () => {
    useEffect(() => {
        let scene, camera, renderer, uniforms;
        const container = document.getElementById('three-container');

        // Initialize the scene
        scene = new THREE.Scene();
        camera = new THREE.Camera();
        camera.position.z = 1;

        // Initialize the renderer
        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        container.appendChild(renderer.domElement);

        // Shader material
        uniforms = {
            u_time: { type: "f", value: 1.0 },
            u_resolution: { type: "v2", value: new THREE.Vector2(window.innerWidth, window.innerHeight) }
        };
    
        const material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: `
                void main() {
                    gl_Position = vec4( position, 1.0 );
                }
            `,
            fragmentShader: `
                uniform vec2 u_resolution;
                uniform float u_time;
    
                float smoothStep(float edge0, float edge1, float x) {
                    float t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
                    return t * t * (3.0 - 2.0 * t);
                }
    
                void main() {
                    vec2 uv = gl_FragCoord.xy / u_resolution.xy;
                    vec3 col = 0.5 + 0.5 * cos(u_time * 0.1 + uv.xyx * vec3(1.0, 1.0, 1.0) + vec3(0.0, 2.0, 4.0));
                    col = smoothstep(0.2, 0.8, col);
                    gl_FragColor = vec4(col, 1.0);
                }
            `
        });

        // Create a plane and add it to the scene
        const geometry = new THREE.PlaneGeometry(2, 2);
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        // Handle window resize
        const onWindowResize = () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            uniforms.u_resolution.value.x = window.innerWidth;
            uniforms.u_resolution.value.y = window.innerHeight;
        };

        window.addEventListener('resize', onWindowResize, false);
        onWindowResize();

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            uniforms.u_time.value += 0.05;
            renderer.render(scene, camera);
        };

        animate();

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', onWindowResize);
            container.removeChild(renderer.domElement);
        };
    }, []);

    return (
        <div id="three-container" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}></div>
    );
};

export default BackgroundEffect;
