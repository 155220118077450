import Web3 from 'web3';

const sankoChainProviderUrl = 'https://mainnet.sanko.xyz';

const initializeWeb3 = async () => {
  let web3;
  // Modern dapp browsers
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      // User denied account access...
      console.error("User denied account access");
    }
  }
  // Legacy dapp browsers
  else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
  }
  // Non-dapp browsers
  else {
    console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    web3 = new Web3(new Web3.providers.HttpProvider(sankoChainProviderUrl));
  }
  return web3;
};

export default initializeWeb3;
