import React from "react";
import BackgroundEffect from "./components/BackgroundEffect";
import NFTDisplay from "./components/NFTDisplay";
import "./App.css"

const App = () => {
  return (
    <div>
      <div className="app">
        <BackgroundEffect />
        <div className="content">
          <NFTDisplay />
        </div>
      </div>
    </div>
  );
};

export default App;
